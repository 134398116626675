import React from 'react';
import Header4 from './../Common/Header4';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import About2 from './../Elements/About2';
import Services1 from './../Elements/Services1';
import Achievements1 from './../Elements/Achievements1';
import Team2 from './../Elements/Team2';
import Testimonials1 from './../Elements/Testimonials1';

var bnrimg = require('./../../images/banner/5.jpg');

class About1 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        return (
            <>
                <Header4 />
                <div className="page-content">
                    <Banner title="About Us" pagename="About" description="" bgimage={bnrimg}/>
                    <About2 />
                    <Testimonials1 separatoralignment="separator-center" />
                    <Services1 />
                    <Achievements1 />
                    <Team2 />
                </div>

                <Footer />
            </>
        );
    };
};

export default About1;