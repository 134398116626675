import React from 'react';

const achievements1 = [
    {
        title: 'Founded Rapidsite Electrical',
        description: 'We officially opened shop and started taking on clients.',
        date: '01',
        month: 'Oct',
        year: '1992',
    },
    {
        title: 'Partnered with Rental Company',
        description: 'Our first partnership to service over 100 rental properties.',
        date: '20',
        month: 'Mar',
        year: '1993',
    },
    {
        title: '30 Clients',
        description: 'We reached 30 clients between commercial and residential.',
        date: '28',
        month: 'Aug',
        year: '1994',
    },
    {
        title: 'Award',
        description: 'We receieved a local award for quality service.',
        date: '20',
        month: 'Oct',
        year: '1996',
    }
]

const achievements2 = [
    {
        title: '100 Clients',
        description: 'We reached 100 clients between commercial and residential.',
        date: '27',
        month: 'Nov',
        year: '1998',
    },
    {
        title: '40 Employees',
        description: 'Our team grew to 40 members.',
        date: '07',
        month: 'Mar',
        year: '2005',
    },
    {
        title: '300 Clients',
        description: 'We reached 300 clients!',
        date: '19',
        month: 'July',
        year: '2010',
    },
    {
        title: '100 Employees',
        description: 'Our family has grown considerably since we founded the company. ',
        date: '27',
        month: 'May',
        year: '2018',
    }
]

var bgimg1 = require('./../../images/background/bg-map.png');
var bgimg2 = require('./../../images/background/cross-line2.png');

class Achievements1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 sx-bg-secondry bg-no-repeat bg-bottom-center mobile-page-padding" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                    <h3 className="sep-line-one">We Achieved</h3>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content ">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 m-b30">
                                    {achievements1.map((item, index) => (
                                        <div className="sx-box our-story text-white" key={index}>
                                            <div className="mt-media our-story-info d-flex justify-content-start">
                                                <div className="our-story-time"><span className="date">{item.date}</span><span className="month">{item.month}</span></div>
                                                <h4 className="text-uppercase our-story-year">{item.year}</h4>
                                            </div>
                                            <div className="our-story-detail">
                                                <h4>{item.title}</h4>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    {achievements2.map((item, index) => (
                                        <div className="sx-box our-story text-white" key={index}>
                                            <div className="mt-media our-story-info d-flex justify-content-start">
                                                <div className="our-story-time"><span className="date">{item.date}</span><span className="month">{item.month}</span></div>
                                                <h4 className="text-uppercase our-story-year">{item.year}</h4>
                                            </div>
                                            <div className="our-story-detail">
                                                <h4>{item.title}</h4>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Achievements1;