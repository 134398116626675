import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: '01',
        title: 'Design and Plan',
        flaticon: 'flaticon-sketch',
        description: 'Need a custom lighting or electrical solution? Our team can design a system tailored to your unique needs and preferences.',
    },
    {
        count: '02',
        title: 'Maintenance',
        flaticon: 'flaticon-stairs',
        description: ' Regular maintenance is key to keeping your electrical system running smoothly. We ensure you keep your system in good shape with routine inspections and maintenance.',
    },
    {
        count: '03',
        title: 'Generator Installations',
        flaticon: 'flaticon-window',
        description: 'Be prepared for power outages and emergencies with a backup generator. Our electricians can install a generator that will keep your lights on and appliances running even when the power is out.',
    },
    {
        count: '04',
        title: '24/7 Services',
        flaticon: 'flaticon-skyline',
        description: 'When you need electrical assistance outside of regular business hours, our emergency electricians are available to handle any issues that arise.',
    },
    {
        count: '05',
        title: 'Upgrades',
        flaticon: 'flaticon-bed',
        description: 'Whether you need a new electrical panel or additional outlets, we can help you upgrade your electrical system to meet your needs. Our experienced electricians will ensure that everything is installed safely and up to code.',
    },
    {
        count: '06',
        title: 'Safety Inspections',
        flaticon: 'flaticon-door',
        description: '  Safety is our top priority. Our certified electricians can conduct a thorough inspection of your electrical system to identify potential hazards and recommend solutions to keep you safe.',
    }
]

var bgimg1 = require('./../../images/background/cross-line2.png');

class Services1 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full mobile-page-padding p-t80  p-b50 bg-gray">
                    <div className="section-content">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-left">
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                        <h3 className="sep-line-one ">All Services</h3>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="row">
                                {services.map((item, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                        <div className="sx-icon-box-wraper  icon-count-2-outer">
                                            <div className="icon-count-2 bg-white">
                                                <span className="icon-count-number">{item.count}</span>
                                                <div className="icon-xl inline-icon m-b5 scale-in-center">
                                                    <span className="icon-cell"><i className={item.flaticon} /></span>
                                                </div>
                                                <div className="icon-content">
                                                    <h4 className="sx-tilte">{item.title}</h4>
                                                    <p>{item.description}</p>
                                                    <div className="text-left">
                                                        <NavLink to={"/contact-us"} className="site-button-link">Contact Us</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Services</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default Services1;