import React from 'react';
import Header4 from './../Common/Header4';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/7.jpg');

class Faq extends React.Component {
    render() {
        return (
            <>
                <Header4 />
                <div className="page-content">
                    <Banner title="Frequently Asked Questions" pagename="Faq" description="" bgimage={bnrimg}/>
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-t80 p-b80 inner-page-padding">
                        <div className="container">
                            <div className="faq-1">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-left">
                                        <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
                                            <h3 className="sep-line-one">FAQ</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                <div className="row">
                                    <div className="col-md-6 faq-list">
                                        {/* Accordian */}
                                        <div>
                                            <h4>Are you insured & licensed?</h4>
                                            <p>At Rapidsite Electrical, our fully insured and licensed electricians guarantee the highest level of safety and professionalism to provide you with top-notch electrical services you can trust.</p>
                                        </div>
                                        <div>
                                            <h4>How long will it take?</h4>
                                            <p>Give us a call & we can work out a solution for you. For simple repairs & upgrades, that can take just a couple hours. For more complex work, we can give you a custom estimate after reviewing the property.</p>
                                        </div>
                                        <div>
                                            <h4>What services do we offer?</h4>
                                            <p>Rapidsite Electrical offers a wide range of electrical services for residential and commercial clients, including:<br></br><br></br>

                                                Electrical wiring and rewiring <br></br>
                                                Panel upgrades and replacements <br></br>
                                                Lighting installations and upgrades <br></br>
                                                Electrical repairs and troubleshooting <br></br>
                                                Outlet and switch installations <br></br>
                                                Surge protection <br></br>
                                                Energy-efficient solutions <br></br>
                                                Emergency electrical services <br></br>
                                                Electric vehicle charging stations <br></br>
                                                Home automation and smart home installations <br></br><br></br>
                                                With our commitment to quality and customer satisfaction, we ensure that your electrical needs are met with expertise and efficiency.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {/* Accordian */}
                                        <div className="sx-accordion acc-bg-gray" id="accordion5">
                                            <div className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                            How do we work?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse show">
                                                    <div className="acod-content p-a15">
                                                        <p>At Everyday Electric, our work process is designed to ensure efficiency, quality, and customer satisfaction. Here's an overview of how we operate:<br></br><br></br>

Initial Consultation: We begin by listening to your needs and understanding the scope of your project. You can reach out to us via phone, email, or our website to schedule a consultation.<br></br><br></br>

Assessment and Quote: Our team of licensed electricians conducts a thorough assessment of your project, taking into consideration all relevant factors. Based on the assessment, we provide you with a detailed, transparent, and competitive quote.<br></br><br></br>

Scheduling and Preparation: Once you approve the quote, we work with you to schedule the project at a time that is most convenient for you. Our team also takes care of any necessary permits and ensures that all required materials are available.<br></br><br></br>

Execution and Quality Control: Our electricians carry out the work as planned, adhering to industry standards, safety regulations, and your specific requirements. Throughout the process, we maintain open communication and ensure that our work meets your expectations.<br></br><br></br>

Final Inspection and Clean-up: Upon completion, we conduct a final inspection to ensure the quality and safety of our work. We also clean up the work area, leaving your property in excellent condition.<br></br><br></br>

Follow-up and Support: After the project is completed, we remain available for any questions, concerns, or additional support you may need. Your satisfaction is our top priority, and we strive to build long-lasting relationships with our clients.<br></br><br></br>

With Rapidsite Electrical, you can expect professional, reliable, and efficient electrical services that cater to your unique needs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                            What services do we offer?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>Rapidsite Electrical offers a wide range of electrical services for residential and commercial clients, including:<br></br><br></br>

Electrical wiring and rewiring <br></br>
Panel upgrades and replacements <br></br>
Lighting installations and upgrades <br></br>
Electrical repairs and troubleshooting <br></br>
Outlet and switch installations <br></br>
Surge protection <br></br>
Energy-efficient solutions <br></br>
Emergency electrical services <br></br>
Electric vehicle charging stations <br></br>
Home automation and smart home installations <br></br><br></br>
With our commitment to quality and customer satisfaction, we ensure that your electrical needs are met with expertise and efficiency.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                           How are our prices?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>At Rapidsite Electrical, we pride ourselves on offering fair and competitive pricing for our services. Here's how we determine the cost for your project:<br></br><br></br>

Assessment: Our licensed electricians conduct a thorough evaluation of your project, taking into account the complexity, materials required, and any specific requirements you may have.<br></br><br></br>

Labor Costs: We calculate the cost of labor based on the estimated hours needed to complete the project, considering our team's expertise and experience.<br></br><br></br>

Materials and Equipment: We provide a detailed breakdown of the materials and equipment required for the project, ensuring that we use high-quality components that deliver both durability and value.<br></br><br></br>

Permits and Licensing: If necessary, we include the cost of obtaining any relevant permits or licenses to ensure that the project complies with local regulations and safety standards.<br></br><br></br>

Overhead and Profit: To maintain our high standards of service and cover operational expenses, we include a reasonable markup for overhead and profit.<br></br><br></br>

By combining these factors, we provide you with a transparent, itemized quote that reflects the true cost of your project. We believe in delivering exceptional service at a competitive price, ensuring that you receive the best value for your investment.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                                            How long will it take to to design and build my project?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFour5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>Progressively generate synergistic total linkage through cross media are intellectual capital. Enthusiastically parallel task team building e-tailers without standards compliant initiatives. Progressively monetize client cent outsourcing with excellent communities..</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                                            What should I have at our first meeting for my project?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFive5" className="acod-body collapse">
                                                    <div className="acod-content p-a15">
                                                    <p>Progressively generate synergistic total linkage through cross media are intellectual capital. Enthusiastically parallel task team building e-tailers without standards compliant initiatives. Progressively monetize client cent outsourcing with excellent communities.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default Faq;